import { isProduction, isMobile } from '$libs/env.ts';
import NPrgoress from 'nprogress';

console.time('App');

const getMetamaskProvider = () => {
    let p: any;
    // @ts-ignore
    if (window.ethereum?.providers?.length) {
        // @ts-ignore
        p = window.ethereum?.providers?.find((p) => p.isMetaMask);
    } else if (window.ethereum?.isMetaMask) {
        p = window.ethereum;
    }
    return p;
};

const next = async () => {
    const Sentry = await import('@sentry/react');
    Sentry.init({
        enabled: isProduction,
        dsn: 'https://4a3c51d5335044e3a7c3799900676225@o4505446097682432.ingest.sentry.io/4505446107971584',
        integrations: [
            new Sentry.BrowserTracing({
                // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [
                    /^https:\/\/staking\.arpanetwork\.io\//,
                    /^https:\/\/eth-mainnet\.g\.alchemy\.com\/v2\//
                ]
            }),
            new Sentry.Replay()
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    NPrgoress.start();
    import('./render.tsx');
};

// fix metamask invalid provider
if (!isMobile) {
    const provider = getMetamaskProvider();
    const wallet = window.localStorage.getItem('SELECTED_WALLET');

    // after closing Coinbase wallet, page will reload,
    // so skip
    if (provider?.request && 'CoinBase' !== wallet) {
        const TIMEOUT = 'TIMEOUT';
        const CHECK = 'Check MetaMask Provider';
        const render = async () => {
            // if request timeout,
            // then we think current metamask provider is invalid,
            // reload page
            console.time(CHECK);
            const result = await Promise.race([
                provider.request({ method: 'wallet_getPermissions' }),
                new Promise((resolve) => {
                    setTimeout(() => {
                        resolve(TIMEOUT);
                    }, 30);
                })
            ]);

            if (TIMEOUT === result) {
                window.location.reload();
            } else {
                console.timeEnd(CHECK);
                next();
            }
        };
        window.requestAnimationFrame(render);
    } else {
        next();
    }
} else {
    next();
}
